// The standard metadata will be overwritten dynamically (articles, authorData)
module.exports = {
  metadata: {
    defaultTitle: 'Age Times',
    titleTemplate: '%s',
    author: 'Age Times',
    facebookId: 'fb:page_id',
    twitterId: 'twitter:account_id',
    url: 'https://www.agetimes.co.uk',
    description: 'Helping over 55’s get even more out of life',
    shortDescription: 'Helping over 55’s get even more out of life'
  }
}
